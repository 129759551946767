import * as THREE from 'three'
import { useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, PivotControls } from '@react-three/drei'
import { Geometry, Base, Subtraction, Addition } from '@react-three/csg'
import { Environment } from './../Environment'
import { GrowPit } from './GrowPit'
import { WaterTank } from './WaterTank'
import { GasTank } from './GasTank'
import { PressureTank } from './PressureTank'
import { LightingRack } from './LightingRack'
import { DryingUnit } from './DryingUnit'
import { WetCutSkip } from './WetCutSkip'

export function HarvestingArea() {
  return (
    <>
        <WetCutSkip meshProps={{color: 'brown'}} position={[36, 0, 12]}/>
        <DryingUnit meshProps={{color: 'yellow'}} position={[36.5, 0, -8]}/>
        <DryingUnit meshProps={{color: 'yellow'}} position={[36.5, 5, -8]}/>
        <DryingUnit meshProps={{color: 'yellow'}} position={[29.5, 0, -8]}/>
        <DryingUnit meshProps={{color: 'yellow'}} position={[29.5, 5, -8]}/>
    </>
  )
}