import * as THREE from 'three'
import { useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, PivotControls } from '@react-three/drei'
import { Geometry, Base, Subtraction, Addition } from '@react-three/csg'
import { Environment } from './../Environment'
import { GrowPit } from './GrowPit'
import { WaterTank } from './WaterTank'
import { GasTank } from './GasTank'
import { PressureTank } from './PressureTank'
import { LightingRack } from './LightingRack'
import { LoadingBay } from './LoadingBay'

export function DispatchingArea() {
  return (
    <>
       <LoadingBay position={[100, 0, -10]}/>
    </>
  )
}