import { pipe, map } from "rxjs/operators";
import { ajax } from "rxjs/ajax";

const BASE_URL =
  "/api";

export const ajaxGetUser = () => ajax.getJSON(`${BASE_URL}/user`);
export const ajaxGetDashboards = () => ajax.getJSON(`${BASE_URL}/sites`);

export const ajaxGetSite = text =>
  ajax({
    url: `${BASE_URL}/XXXXXX`,
    method: "POST",
    body: { text }
  }).pipe(map(data => data.response));

export const ajaxCompleteSite = id =>
  ajax({
    url: `${BASE_URL}/site/${id}/complete`,
    method: "POST"
  }).pipe(map(data => data.response));