import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import LaneCard from './LaneCard.js'

export default function Schedules() {
  return (
    <>
      <Grid container spacing={2}>
         <Grid item xs={6}>
            <Skeleton variant="rounded" width={60} height={60} />
         </Grid>
         <Grid item xs={2}>
             <Skeleton variant="rounded" width={60} height={60} />
         </Grid>
         <Grid item xs={2}>
            <Skeleton variant="rounded" width={60} height={60} />
         </Grid>
         <Grid item xs={2}>
            <Skeleton variant="rounded" width={60} height={60} />
         </Grid>
       </Grid>

       <Grid container spacing={2}>
        <Grid item xs={6}>
           <Skeleton variant="rounded" width={60} height={60} />
        </Grid>
        <Grid item xs={2}>
            <Skeleton variant="rounded" width={60} height={60} />
        </Grid>
        <Grid item xs={2}>
           <Skeleton variant="rounded" width={60} height={60} />
        </Grid>
        <Grid item xs={2}>
           <Skeleton variant="rounded" width={60} height={60} />
        </Grid>
      </Grid>
    </>
  );
}