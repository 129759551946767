import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import LaneCard from './LaneCard.js'
import Link from '@mui/material/Link';

function Variants() {
  return (
    <Stack spacing={1}>
      {/* For variant="text", adjust the height via font-size */}
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
      {/* For other variants, adjust the size with `width` and `height` */}
      <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant="rectangular" width={210} height={60} />
      <Skeleton variant="rounded" width={210} height={60} />
    </Stack>
  );
}

export default function SitesDashboard({sites}) {

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
         <Link href="/site/02-alpha" underline="none">
         02 Alpha
         </Link>
        </AccordionSummary>

      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Link href="/site/03-alpha" underline="none">
           03 Alpha
           </Link>
        </AccordionSummary>
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={6}>
               <LaneCard/>
            </Grid>
            <Grid item xs={6}>
               <LaneCard/>
            </Grid>
            <Grid item xs={6}>
               <LaneCard/>
            </Grid>
            <Grid item xs={6}>
               <LaneCard/>
            </Grid>
          </Grid>
         </Container>
      </Accordion>
    </>
  );
}