import * as THREE from 'three'
import { useRef } from 'react'
import { OrbitControls, PivotControls } from '@react-three/drei'
import { Geometry, Base, Subtraction, Addition } from '@react-three/csg'

const cap = new THREE.CapsuleGeometry(2, 3);
export function PressureTank(props) {
  const csg = useRef()
  const {meshProps,...restProps} = props
  return (
    <mesh receiveShadow castShadow {...restProps}>
      <Geometry ref={csg} computeVertexNormals>
        <Base name="base" geometry={cap}/>
      </Geometry>
      <meshStandardMaterial
      {...meshProps} />
    </mesh>
  )
}