import { createStore, combineReducers, applyMiddleware } from "redux";
import { ofType, combineEpics, createEpicMiddleware } from "redux-observable";
import { map, flatMap } from "rxjs/operators";
import { ajax } from "rxjs/ajax";

import {
  GET_USER,
  GET_USER_SUCCESS,
  GET_DASHBOARDS,
  GET_DASHBOARDS_SUCCESS,
  GET_DASHBOARDS,
  GET_DASHBOARDS_SUCCESS,
  COMPLETE_DASHBOARDS,
  COMPLETE_DASHBOARDS_SUCCESS,
  getUserSuccess,
  getDashboardsSuccess,
  getSiteSuccess,
  completeSiteSuccess
} from "./actions";

import {
    ajaxGetSite,
    ajaxGetDashboards,
    ajaxGetUser,
    ajaxCompleteSite
} from "./api"

function user(state = {}, action) {
  switch (action.type) {
    case GET_USER_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

function sites(state = [], action) {
  switch (action.type) {
    case GET_DASHBOARDS_SUCCESS:
    debugger;
      return [...state, action.payload];
    case COMPLETE_DASHBOARDS_SUCCESS:
      return state.map(site => {
        if (site.id === action.payload.id) {
          return { ...site, completed: true };
        }
        return site;
      });
    default:
      return state;
  }
}

const rootReducer = combineReducers({ user, sites });

const userEpic = action$ =>
  action$.pipe(
    ofType(GET_USER),
    flatMap(() => ajaxGetUser()),
    map(user => getUserSuccess(user))
  );

const getDashboardsEpic = action$ =>
  action$.pipe(
    ofType(GET_DASHBOARDS),
    flatMap(() => ajaxGetDashboards()),
    map(({  }) => getDashboardsSuccess(sites))
  );

//const getSiteEpic = action$ =>
//  action$.pipe(
//    ofType(GET_DASHBOARDS),
//    flatMap(action => ajaxGetSite(action.payload)),
//    map(site => getSiteSuccess(site))
//  );
const completeSiteEpic = action$ =>
  action$.pipe(
    ofType(COMPLETE_DASHBOARDS),
    flatMap(action => ajaxCompleteSite(action.payload)),
    map(site => completeSiteSuccess(site))
  );
const epicMiddleware = createEpicMiddleware();
const store = createStore(rootReducer, applyMiddleware(epicMiddleware));
epicMiddleware.run(combineEpics(
 userEpic,
 getDashboardsEpic,
 completeSiteEpic
))
export {store}