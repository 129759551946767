import * as THREE from 'three'
import { useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, PivotControls } from '@react-three/drei'
import { Geometry, Base, Subtraction, Addition } from '@react-three/csg'
import { Environment } from './../Environment'
import { GrowPit } from './GrowPit'
import { WaterTank } from './WaterTank'
import { GasTank } from './GasTank'
import { PressureTank } from './PressureTank'
import { TrimmingSkip } from './TrimmingSkip'
import { ProcessingUnit } from './ProcessingUnit'

export function ProcessingArea() {
  return (
    <>
        <TrimmingSkip meshProps={{color: 'brown'}} position={[46.5, 0, 4]}/>
        <ProcessingUnit  meshProps={{color: 'yellow'}} position={[46.5, 0, -16]}/>
    </>
  )
}