import * as THREE from 'three'
import { useRef } from 'react'
import { OrbitControls, PivotControls } from '@react-three/drei'
import { Geometry, Base, Subtraction, Addition } from '@react-three/csg'

const cyl = new THREE.CylinderGeometry(3, 3, 10, 16)

export function WaterTank(props) {
  const csg = useRef()
  const {meshProps,...restProps} = props
  return (
    <mesh receiveShadow castShadow {...restProps}>
      <Geometry ref={csg} computeVertexNormals>
        <Base name="base" geometry={cyl}/>
      </Geometry>
      <meshStandardMaterial {...meshProps} />
    </mesh>
  )
}