import * as THREE from 'three'
import { useRef } from 'react'
import { OrbitControls, PivotControls } from '@react-three/drei'
import { Geometry, Base, Subtraction, Addition } from '@react-three/csg'

const box = new THREE.BoxGeometry(8, 1.5, 4)

export function ProcessingUnit(props) {
  const csg = useRef()
  const {meshProps,...restProps} = props
  return (
    <mesh receiveShadow castShadow {...restProps}>
      <Geometry ref={csg} computeVertexNormals>
        <Base name="base" geometry={box}/>
         <Addition position={[6, 0, 10]}>
            <boxGeometry args={[4, 1.5, 40]} />
            <meshNormalMaterial />
          </Addition>
          <Addition position={[10, 0, 32]}>
              <boxGeometry args={[12, 1.5, 4]} />
              <meshNormalMaterial />
            </Addition>
      </Geometry>
      <meshStandardMaterial {...meshProps} />
    </mesh>
  )
}