import * as THREE from 'three'
import { useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, PivotControls } from '@react-three/drei'
import { Geometry, Base, Subtraction, Addition } from '@react-three/csg'
import { Environment } from './../Environment'
import { GrowPit } from './GrowPit'
import { WaterTank } from './WaterTank'
import { GasTank } from './GasTank'
import { PressureTank } from './PressureTank'
import { LightingRack } from './LightingRack'
import { DryingRack } from './DryingRack'

export function DryingArea() {
  return (
    <>
      <DryingRack position={[30, 0.3, 0]}/>
      <DryingRack position={[30, 0.6, 0]}/>
      <DryingRack position={[30, 0.9, 0]}/>
      <DryingRack position={[30, 1.2, 0]}/>
      <DryingRack position={[30, 1.5, 0]}/>

      <DryingRack position={[30, 0.3, 1.2]}/>
      <DryingRack position={[30, 0.6, 1.2]}/>
      <DryingRack position={[30, 0.9, 1.2]}/>
      <DryingRack position={[30, 1.2, 1.2]}/>
      <DryingRack position={[30, 1.5, 1.2]}/>

    </>
  )
}