import React from "react";
import { connect } from "react-redux";

import { getDashboards, completeSite, getSite } from "./actions";

import WebappTabs from './WebappTabs'

class WebPage extends React.Component {

  componentDidMount() {

  }

  render() {
    const { style, sites } = this.props;
    return (
      <>
        GROWCLOUD
      </>
    );
  }
}

function mapStateToProps(state) {
  return {  };
}

export default connect(
  mapStateToProps
)(WebPage)