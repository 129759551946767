import * as THREE from 'three'
import { useRef } from 'react'
import { OrbitControls, PivotControls } from '@react-three/drei'
import { Geometry, Base, Subtraction, Addition } from '@react-three/csg'

const box = new THREE.BoxGeometry(0.3, 12, 40)

export function Wall(props) {
  const csg = useRef()
  const {meshProps,...restProps} = props
    return (
      <mesh receiveShadow castShadow {...restProps}>
        <Geometry ref={csg} computeVertexNormals>
          <Base name="base" geometry={box}/>
        </Geometry>
        <meshStandardMaterial {...meshProps} />
      </mesh>
    )
}