import * as THREE from 'three'
import { useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, PivotControls } from '@react-three/drei'
import { Geometry, Base, Subtraction, Addition } from '@react-three/csg'
import { Environment } from './../Environment'
import { GrowPit } from './GrowPit'
import { WaterTank } from './WaterTank'
import { GasTank } from './GasTank'
import { PressureTank } from './PressureTank'
import { LightingRack } from './LightingRack'
import { GasSupplyGantry } from './GasSupplyGantry'
import { WaterSupplyGantry } from './WaterSupplyGantry'
import { GasPressureUnit } from './GasPressureUnit';
import { WaterPressureUnit } from './WaterPressureUnit';

export function LightingUnit() {
 let waterMeshProps =  {color: 'green'}
 let gasMeshProps =  {color: 'silver'}

  return (
    <>
      <LightingRack position={[0, 3.1, 0]}/>
      <LightingRack position={[12, 3.1, 0]}/>
      <LightingRack position={[-12, 3.1, 0]}/>

      <LightingRack position={[0, 3.1, 12]}/>
      <LightingRack position={[12, 3.1, 12]}/>
      <LightingRack position={[-12, 3.1, 12]}/>
    </>
  )
}