import * as THREE from 'three'
import { useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, PivotControls } from '@react-three/drei'
import { Geometry, Base, Subtraction, Addition } from '@react-three/csg'
import { Environment } from './../Environment'
import { GrowPit } from './GrowPit'
import { WaterTank } from './WaterTank'
import { GasTank } from './GasTank'
import { HoldingUnit } from './HoldingUnit'
import { SettlingUnit } from './SettlingUnit'
import { PackagingUnit } from './PackagingUnit'
import { ContainerUnit } from './ContainerUnit'

export function HoldingArea() {
  return (
    <>
        <SettlingUnit meshProps={{color: 'yellow'}} position={[72, 0, -10]}/>
        <PackagingUnit meshProps={{color: 'white'}} position={[72, 0, 10]}/>
        <HoldingUnit meshProps={{color: 'green'}} position={[92, 0, 0]}/>
    </>
  )
}