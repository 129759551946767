export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_DASHBOARDS = "GET_DASHBOARDS";
export const GET_DASHBOARDS_SUCCESS = "GET_DASHBOARDS_SUCCESS";
export const GET_SITE = "GET_SITE";
export const GET_SITE_SUCCESS = "GET_SITE_SUCCESS";
export const COMPLETE_DASHBOARDS = "COMPLETE_DASHBOARDS";
export const COMPLETE_DASHBOARDS_SUCCESS = "COMPLETE_DASHBOARDS_SUCCESS";

export const getUser = () => ({ type: GET_USER });
export const getUserSuccess = user => ({
  type: GET_USER_SUCCESS,
  payload: user
});

export const getDashboards = () => ({ type: GET_DASHBOARDS });
export const getDashboardsSuccess = sites => ({
  type: GET_DASHBOARDS_SUCCESS,
  payload: sites
});
//export const getSite = text => ({ type: GET_SITE, payload: text });
//export const getSiteSuccess = site => ({
//  type: GET_SITE_SUCCESS,
//  payload: site
//});
export const completeSite = id => ({ type: COMPLETE_DASHBOARDS, payload: id });
export const completeSiteSuccess = site => ({
  type: COMPLETE_DASHBOARDS_SUCCESS,
  payload: site
});