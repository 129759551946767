import * as THREE from 'three'
import { useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, PivotControls } from '@react-three/drei'
import { Geometry, Base, Subtraction, Addition } from '@react-three/csg'
import { Environment } from './../Environment'
import { GrowPit } from './GrowPit'
import { WaterTank } from './WaterTank'
import { GasTank } from './GasTank'
import { PressureTank } from './PressureTank'
import { Wall } from './Wall'
import { GasSupplyGantry } from './GasSupplyGantry'
import { WaterSupplyGantry } from './WaterSupplyGantry'
import { GasPressureUnit } from './GasPressureUnit';
import { WaterPressureUnit } from './WaterPressureUnit';

export function WallUnit() {

 let wallMeshProps = {color: 'indigo', opacity: 0.1, transparent: true }

  return (
    <>
      <Wall position={[-36, 0, 0]} meshProps={wallMeshProps}/>

      <Wall position={[-40, 0, 0]} meshProps={wallMeshProps}/>

      <Wall position={[26, 0, 0]} meshProps={wallMeshProps}/>

      <Wall position={[40, 0, 0]} meshProps={wallMeshProps}/>

      <Wall position={[56, 0, 0]} meshProps={wallMeshProps}/>

    </>
  )
}