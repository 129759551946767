import React from "react";
import { Provider } from "react-redux";
import { store } from "./WebappCore";
import LandingPage from './LandingPage'
import WebPage from './WebPage'
import TestSite from '../3d/TestSite'

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([{
    path: "/app",
    element: (<LandingPage/>),
  },{
       path: "/test-site",
       element: (<TestSite/>),
 },{
    path: "/",
    element: (<WebPage/>),
  },
]);

export default function Webapp() {
  return (
    <Provider store={store}>
       <RouterProvider router={router} />
    </Provider>
  )
}
