import * as THREE from 'three'
import { useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, PivotControls } from '@react-three/drei'
import { Geometry, Base, Subtraction, Addition } from '@react-three/csg'
import { Environment } from './../Environment'
import { GrowPit } from './GrowPit'
import { WaterTank } from './WaterTank'
import { GasTank } from './GasTank'
import { PressureTank } from './PressureTank'
import { LightingRack } from './LightingRack'
import { LightingUnit } from './LightingUnit'
import { WallUnit } from './WallUnit'

import { GasSupplyGantry } from './GasSupplyGantry'
import { WaterSupplyGantry } from './WaterSupplyGantry'
import { GasPressureUnit } from './GasPressureUnit';
import { WaterPressureUnit } from './WaterPressureUnit';
import { ExhaustGantry } from './ExhaustGantry'

export function GrowingArea() {
 let waterSupplyMeshProps =  {color: 'green'}
 let gasSupplyMeshProps =  {color: 'silver'}

 let growPitMeshProps =  {color: 'indigo', opacity: 0.1, transparent: true }
 let gasExhaustMeshProps = {color: 'brown'}

  return (
    <>
      <WallUnit/>
      <LightingUnit/>

      <GrowPit meshProps={growPitMeshProps} position={[0, 0, 0]}/>
      <GrowPit meshProps={growPitMeshProps} position={[12, 0, 0]}/>
      <GrowPit meshProps={growPitMeshProps} position={[-12, 0, 0]}/>

      <GrowPit meshProps={growPitMeshProps} position={[0, 0, 12]}/>
      <GrowPit meshProps={growPitMeshProps} position={[12, 0, 12]}/>
      <GrowPit meshProps={growPitMeshProps} position={[-12, 0, 12]}/>

      <GasPressureUnit meshProps={gasSupplyMeshProps} />

      <WaterPressureUnit meshProps={waterSupplyMeshProps} />

      <GasSupplyGantry meshProps={gasSupplyMeshProps} position={[16, 6.0, -6]}/>
      <GasSupplyGantry meshProps={gasSupplyMeshProps} position={[16, 6.0, -9]}/>

      <ExhaustGantry meshProps={gasExhaustMeshProps} position={[16, 6.0, -2.1]}/>

      <WaterSupplyGantry meshProps={waterSupplyMeshProps} position={[16, 6.0, 6]}/>
      <WaterSupplyGantry meshProps={waterSupplyMeshProps} position={[16, 6.0, 9]}/>

    </>
  )
}