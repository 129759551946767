import React from "react";
import { connect } from "react-redux";

import { getDashboards, completeSite, getSite } from "./actions";

import WebappTabs from './WebappTabs'

class LandingPage extends React.Component {

  componentDidMount() {
    this.props.dispatch(getDashboards())
  }

  render() {
    const { style, sites } = this.props;
    return (
      <>
        <WebappTabs sites={sites}/>
      </>
    );
  }
}

function mapStateToProps(state) {
  return { sites: state.sites };
}

export default connect(
  mapStateToProps
)(LandingPage)