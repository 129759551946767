import * as THREE from 'three'
import { useRef } from 'react'
import { OrbitControls, PivotControls } from '@react-three/drei'
import { Geometry, Base, Subtraction, Addition } from '@react-three/csg'

const box = new THREE.BoxGeometry(6, 4, 16)

export function DryingUnit(props) {
  const csg = useRef()
  const {meshProps,...restProps} = props
  return (
    <mesh receiveShadow castShadow {...restProps}>
      <Geometry ref={csg} computeVertexNormals>
        <Base name="base" geometry={box}/>
      </Geometry>
      <meshStandardMaterial {...meshProps} />
    </mesh>
  )
}