import * as THREE from 'three'
import { useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, PivotControls } from '@react-three/drei'
import { Geometry, Base, Subtraction, Addition } from '@react-three/csg'
import { Environment } from './Environment'
import { GrowPit } from './units/GrowPit'
import { WaterTank } from './units/WaterTank'
import { GasTank } from './units/GasTank'
import { PressureTank } from './units/PressureTank'
import { LightingRack } from './units/LightingRack'
import { GrowingArea } from './units/GrowingArea'
import { DryingArea } from './units/DryingArea'
import { GerminationArea } from './units/GerminationArea'
import { ReceivingArea } from './units/ReceivingArea'
import { DispatchingArea } from './units/DispatchingArea'
import { HarvestingArea } from './units/HarvestingArea'
import { HoldingArea } from './units/HoldingArea'
import { ProcessingArea } from './units/ProcessingArea'

export default function TestSite () {
  return (
    <Canvas shadows camera={{ position: [-15, 10, 15], fov: 25 }}>
      <color attach="background" args={['orange']} scale={[3, 3, 3]}/>
      <GrowingArea/>
      <HarvestingArea/>
      <HoldingArea/>
      <ProcessingArea/>
      <GerminationArea/>
      <Environment />
      <ReceivingArea/>
      <DispatchingArea/>
      <OrbitControls makeDefault />
    </Canvas>
  )
}