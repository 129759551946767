import * as THREE from 'three'
import { useRef } from 'react'
import { OrbitControls, PivotControls } from '@react-three/drei'
import { Geometry, Base, Subtraction, Addition } from '@react-three/csg'

class JuicyLine extends THREE.Curve {

	constructor( scale = 1 ) {
		super();
		this.scale = scale;
	}

	getPoint(t, optionalTarget = new THREE.Vector3()) {
		const tx = t * -26;
		const ty = 0;
		const tz = 3;
		return optionalTarget.set(tx, ty, tz).multiplyScalar(this.scale)
	}
}

const gasPath = new JuicyLine( 2 )
const gasTube = new THREE.TubeGeometry( gasPath, 16, 1, 12, false );

export function GasSupplyGantry(props) {
  const csg = useRef()
  const {meshProps,...restProps} = props
  return (
    <mesh receiveShadow castShadow {...restProps}>
      <Geometry ref={csg} computeVertexNormals>
        <Base name="gas00" geometry={gasTube}/>
      </Geometry>
      <meshStandardMaterial {...meshProps} />
    </mesh>
  )
}